// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_4eCoT{display:flex;flex-direction:column;flex-grow:1;height:100%;width:100%}.item_Cnxbj{margin-top:1rem;min-height:10rem;width:calc(33% - .5rem)}@media screen and (min-width:1441px){.item_Cnxbj{width:calc(25% - .75rem)}}@media screen and (max-width:1024px){.item_Cnxbj{width:calc(50% - .5rem)}}.row_mq\\+qz{display:flex;flex:0 0 auto;flex-flow:row wrap;gap:1rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_4eCoT",
	"item": "item_Cnxbj",
	"row": "row_mq+qz"
};
module.exports = ___CSS_LOADER_EXPORT___;
