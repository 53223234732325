// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".background_e3X3i,.container_BGmz1,.slot_3Y2mK{display:flex;flex-direction:column}.container_BGmz1{height:1.5rem;overflow:hidden;position:relative;width:1.5rem}.background_e3X3i{align-items:center;background-color:var(--color-dropdown-background-color);border-radius:1rem;height:100%;justify-content:center;position:absolute;width:100%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"background": "background_e3X3i",
	"container": "container_BGmz1",
	"slot": "slot_3Y2mK"
};
module.exports = ___CSS_LOADER_EXPORT___;
