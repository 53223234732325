// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".aside_fcrDm,.colorDropdown_YYB6F,.container_UjvDW,.header_BJqWJ,.label_x6Avr{display:flex}.header_BJqWJ{align-items:center}.container_UjvDW{border-radius:var(--border-radius-large);box-sizing:border-box;flex-direction:column;height:100%}.header_BJqWJ .colorDropdown_YYB6F{opacity:0;transition-duration:var(--transition-duration-short);transition-property:opacity;transition-timing-function:ease-in-out}.header_BJqWJ:hover .colorDropdown_YYB6F{opacity:1}.header_BJqWJ{justify-content:space-between}.label_x6Avr{flex-direction:column}.aside_fcrDm{align-items:flex-start}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"aside": "aside_fcrDm",
	"colorDropdown": "colorDropdown_YYB6F",
	"container": "container_UjvDW",
	"header": "header_BJqWJ",
	"label": "label_x6Avr"
};
module.exports = ___CSS_LOADER_EXPORT___;
